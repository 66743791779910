<template>
  <section-hero></section-hero>
  <!-- <section-vision></section-vision> -->
  <section-value-of-business></section-value-of-business>
  <section-reason></section-reason>
  <section-news-feedback></section-news-feedback>
</template>
<script setup lang="ts">
import {useNuxtApp} from 'nuxt/app'
import sectionHero from './components/SectionHero.vue'
import sectionVision from './components/SectionVision.vue'
import sectionValueOfBusiness from './components/SectionValueOfBusiness.vue'
import sectionReason from './components/SectionReason.vue'
import sectionNewsFeedback from './components/SectionNewsFeedback.vue'
// import sectionContact from './components/sectionContact/index.vue'
import {useGetLangCurrent} from '../../stores/useLanguageMenu'

const settings = inject('globalSettings')
const {generateLangSwitch} = useGenerateUrl(settings)
const lang_switch = generateLangSwitch('about', useRoute().params.langCode)
const lang = useRoute().params.lang
useDirectusCollectionSeo('about_index', {}, lang)
const store = useGetLangCurrent()

onMounted(() => {
  store.lang_switch = lang_switch
})
</script>
