<template>
  <section>
    <div class="mt-10 lg:mt-25 container">
      <div class="flex flex-col xl:flex-row justify-between gap-10 lg:gap-20">
        <div class="w-full">
          <div
              data-aos="fade-right"
              data-aos-once="true"
              class="text-sm font-semibold text-bw-09/50 uppercase"
          >
            <v-text tag="p" option_key="about.subtitleReason"></v-text>
          </div>
          <div
              data-aos="fade-right"
              data-aos-once="true"
              data-aos-offset="-50"
              class="text-2xl lg:text-35px font-[550] text-black leading-10 mt-2"
          >
            <v-content option_key="about.titleReason"/>
          </div>
        </div>
      </div>
      <div class="w-full h-1px bg-bw-08 mt-10 lg:mt-15"></div>
      <!-- <div data-aos="zoom-in-up" data-aos-once="true" class="hidden lg:block">
          <div class="h-26.5 lg:h-47.5 w-full flex items-center" v-if="listPartner1">
              <div class="wrapper-marquee">
                  <div class="marquee">
                      <div class="marquee_group move_left">
                          <template v-for="partner in listPartner1" :key="partner?.id">
                              <a :href="partner?.url" class="!flex justify-center items-center">
                                  <img
                                      :src="partner?.thumbnail"
                                      alt="partner"
                                      class="w-12 lg:w-20 aspect-1/1 object-contain"
                                  />
                              </a>
                          </template>
                      </div>
                      <div class="marquee_group move_left">
                          <template v-for="partner in listPartner1" :key="partner?.id">
                              <a :href="partner?.url" class="!flex justify-center items-center">
                                  <img
                                      :src="partner?.thumbnail"
                                      alt="partner"
                                      class="w-12 lg:w-20 aspect-1/1 object-contain"
                                  />
                              </a>
                          </template>
                      </div>
                  </div>
              </div>
          </div>
          <div class="h-26.5 lg:h-47.5 w-full flex items-center" v-if="listPartner2">
              <div class="wrapper-marquee">
                  <div class="marquee">
                      <div class="marquee_group move_right">
                          <template v-for="partner in listPartner2" :key="partner?.id">
                              <a :href="partner?.url" class="!flex justify-center items-center">
                                  <img
                                      :src="partner?.thumbnail"
                                      alt="partner"
                                      class="w-12 lg:w-20 aspect-1/1 object-contain"
                                  />
                              </a>
                          </template>
                      </div>
                      <div class="marquee_group move_right">
                          <template v-for="partner in listPartner2" :key="partner?.id">
                              <a :href="partner?.url" class="!flex justify-center items-center">
                                  <img
                                      :src="partner?.thumbnail"
                                      alt="partner"
                                      class="w-12 lg:w-20 aspect-1/1 object-contain"
                                  />
                              </a>
                          </template>
                      </div>
                  </div>
              </div>
          </div>
      </div> -->
      <div data-aos="fade-up" data-aos-once="true" class="relative">
        <Swiper
            :modules="[SwiperAutoplay, SwiperPagination, SwiperGrid]"
            :autoplay="{
                      delay: 3000,
                      disableOnInteraction: false,
                    }"
            :space-between="20"
            :breakpoints="{
                        300: { slidesPerView: 3 },
                        800: { slidesPerView: 4 },
                        1040: { slidesPerView: 6 }
                    }"
            :pagination="{
                        dynamicBullets: true,
                        clickable: true
                    }"
            :grid="{
                        rows: `${partners && partners.length > 0 && partners.length >= 12 ? 2 : 1}`
                    }"
            :grabCursor="true"
            class="swiper-partner-about !pb-10"
        >
          <SwiperSlide class="!h-auto !mt-0" v-for="(item, index) in partners" :key="index">
            <div class="h-30 w-full flex items-center justify-center">
              <a :href="item?.url" target="_blank" class="!flex justify-center items-center">
                <img
                    :src="getThumbnail(item?.thumbnail?.id, 150)"
                    :alt="item?.thumbnail?.description || item?.thumbnail?.title"
                    class="w-20 aspect-1/1 object-contain transform"
                />
              </a>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
      <!-- <div
          data-aos="fade-up"
          data-aos-once="true"
          class="grid grid-cols-3 sm:grid-cols-4 lg:grid-cols-6 gap-y-10"
      >
          <div
              v-for="item in 12"
              class="w-20 h-20 sm:w-30 sm:h-30 flex flex-col items-center justify-center"
          >
              <img src="/images/BAOVIET-brand.svg" alt="" class="w-full h-full" />
          </div>
      </div> -->
      <div
          :class="partners && partners.length > 12 ? 'mt-5' : 'mt-0'"
          class="w-full h-1px bg-bw-04/15 mb-15"
      ></div>
    </div>
  </section>
</template>
<script lang="ts" setup>
import 'swiper/css'
import 'swiper/css/grid'

const {getCarriers} = useCarrier()

const partners = await getCarriers(useRoute().params.langCode, {})
</script>
<style>
.swiper {
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}

.swiper-grid-column > .swiper-wrapper {
  flex-direction: unset !important;
}

.swiper-partner-about .swiper-pagination {
  @apply bottom-0 !w-28.5;
}

.swiper-partner-about .swiper-pagination > .swiper-pagination-bullet.swiper-pagination-bullet-active {
  @apply !w-6.5;
}
</style>
