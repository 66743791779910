<template>
  <div
      class="w-50 sm:w-60 md:w-80 lg:w-77 xl:w-95 aspect-1/1 flex flex-col items-center justify-center rounded-full bg-primary borderImage p-2.5"
  >
    <v-image :option_key="option_key" :width="700" class="rounded-full object-center w-full h-full object-cover">
    </v-image>
  </div>
</template>
<script setup lang="ts">
defineProps({
  image: {
    type: String
  },
  option_key: {
    type: String
  }
})
const is_edit = inject('is_edit')
</script>
<style>
.borderImage {
  background: var(--gradient, linear-gradient(140deg, #fe210c 0%, #febd29 100%));
}

.borderImage > div {
  @apply !w-full !h-full;
}
</style>
