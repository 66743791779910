<template>
  <section class="">
    <div class="relative pt-15 pb-20 lg:pt-20 xl:pt-30 xl:pb-30 overflow-hidden">
      <div class="absolute -bottom-60 right-0 transform -translate-x-1/2 z-100">
        <img src="/images/about/pattern-reason.svg" alt="icon_pattern" class="w-full h-full"/>
      </div>
      <div class="absolute lg:-left-60 xl:-left-30 top-0 z-10">
        <img src="/images/about/pattern_Z.svg" class="w-full h-full object-contain" alt="icon_pattern"/>
      </div>
      <div class="bg-wrapper w-full h-full object-cover z-1 absolute inset-0"></div>
      <!-- <img src="/images/about/banner-hero.png" alt="" class="w-full h-full object-cover z-1 absolute inset-0" /> -->
      <div class="container">
        <div
            data-aos="fade-right"
            data-aos-once="true"
            class="relative flex flex-col lg:flex-row lg:items-center lg:justify-between z-10 gap-10 lg:gap-30 z-20 mb-15 lg:mb-20"
        >
          <div class="w-full lg:w-1/2 lg:max-w-125">
            <GlobalHeading
                class="leading-68px"
                opt-key-content="about.contentHero"
                opt-key-subtitle="about.subtitleHero"
                sub-title="Về chúng tôi"
                title="Tầm nhìn sứ mệnh"
                description="Ứng dụng IZIon24 là giải pháp bảo hiểm thông minh chính thức ra mắt vào tháng 5/2022, đánh dấu bước đi mang tính đổi mới, với việc áp dụng những công nghệ hiện đại nhất để quản lý thông tin, giúp người mua dễ dàng ra quyết định mua, và được hỗ trợ 24/7.
Ứng dụng này được kỳ vọng sẽ mở ra nhiều lựa chọn cho người Việt Nam để bảo vệ gia đình và bản thân nhờ vào việc áp dụng chuyển đổi kỹ thuật số thông minh.
IZIon24 hợp tác với công ty tư vấn bảo hiểm hàng đầu trên thế giới để phân tích các khảo sát nhu cầu khách hàng, từ đó cho ra đời những sản phẩm bảo hiểm được thiết kế riêng, phù hợp với nhu cầu của nhiều đối tượng khách hàng khác nhau."
                :sizeTitle="'text-46px  !lg:text-56px !xl:text-60px leading-46px !lg:leading-55px !xl:leading-68px'"
                light
            ></GlobalHeading>
          </div>

          <div class="w-full max-w-sm md:max-w-lg lg:max-w-xl lg:w-1/2 z-1 relative z-10 mx-auto">
            <div
                data-aos="fade-left"
                data-aos-easing="ease-in-sine"
                data-aos-once="true"
                class="aspect-152/159 w-full"
            >
              <v-image
                  :width="700"
                  class="rounded-15px w-full h-full object-cover"
                  option_key="about.imageHero"
              >
              </v-image>
            </div>

            <div
                data-aos="fade-down-left"
                data-aos-delay="300"
                data-aos-once="true"
                class="w-30 h-20 md:w-50 md:h-40 absolute -bottom-10 right-0 lg:-right-20 z-1"
            >
              <img
                  src="/images/brand-iz4.png"
                  alt="brand-iz4"
                  class="w-full h-full object-cover animationSway"
              />
            </div>
          </div>
        </div>
        <div class="relative flex flex-col-reverse lg:flex-row items-start z-10 gap-10 lg:gap-20 h-full">
          <div
              data-aos="zoom-in-up"
              data-aos-once="true"
              class="relative top-0 w-full lg:w-7/12 xl:w-8/12 h-80 sm:h-100 md:h-130 lg:h-140 xl:h-150 max-w-sm sm:max-w-md md:max-w-xl lg:max-w-full mx-auto"
          >
            <div class="absolute left-1/2 transform -translate-x-1/2 z-10">
              <CircleImage option_key="about.circleImage1"/>
            </div>
            <div class="absolute left-0 bottom-0 z-20">
              <CircleImage option_key="about.circleImage2"/>
            </div>
            <div class="absolute right-0 lg:right-4 bottom-0 z-1">
              <CircleImage option_key="about.circleImage3"/>
            </div>
          </div>
          <div class="w-full lg:w-5/12">
            <div data-aos="fade-right" data-aos-easing="ease-in-sine" data-aos-once="true">
              <GlobalHeading
                  class="leading-68px"
                  optKeyTitle="about.titleHero"
                  :formEditor="true"
                  title="Tầm nhìn sứ mệnh"
                  :sizeTitle="'text-46px  !lg:text-56px !xl:text-60px leading-46px !lg:leading-55px !xl:leading-68px'"
                  light
              ></GlobalHeading>
            </div>
            <div
                v-for="(item, index) in dataVison"
                :key="index"
                data-aos="fade-up"
                data-aos-delay="100"
                data-aos-once="true"
                class="flex flex-col items gap-5"
            >
              <div class="w-full h-1px bg-white/20 mt-4 lg:mt-7.5 mb-1.5"></div>
              <v-content
                  class="text-white text-xl font-[550] flex-1"
                  :option_key="'about.contentHeroSub' + index"
                  :default_value="`${item.title}  <p class='text-white/85 text-sm !xl:text-15px font-normal pt-3'>${item.content}</p>`"
              ></v-content>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script setup lang="ts">
import CircleImage from './__CircleImage.vue'

const dataVison = [
  {
    title: 'Tầm nhìn',
    content: 'IZION24 nuôi ước vọng mang đến sự bảo vệ đáng tin cậy cho người Việt thông qua những ứng'
  },
  {
    title: 'Sứ mệnh',
    content: 'IZION24 nuôi ước vọng mang đến sự bảo vệ đáng tin cậy cho người Việt thông qua những ứng'
  },
  {
    title: 'Mục đích',
    content: 'IZION24 nuôi ước vọng mang đến sự bảo vệ đáng tin cậy cho người Việt thông qua những ứng'
  }
]
</script>
<style scoped>
.img-rounded > img.le-image-img {
  @apply rounded-20px w-full h-full;
}

.bg-wrapper {
  background: radial-gradient(162.55% 98.99% at 73.6% 64.69%, #ffa17d 5.03%, #ff8050 42.75%, #ed5a22 100%);
}
</style>
