<template>
  <section class="container mt-10 mb-10 lg:mt-28 lg:mb-27">
    <div
        data-aos="fade-right"
        data-aos-once="true"
        class="text-2xl lg:text-35px font-[550] text-black leading-10 mt-2"
    >
      <v-content
          default_value="Yên tâm đồng hành và phát triển cùng IZIon24"
          option_key="about.titleNewsFeedBack"
      />
    </div>
    <div data-aos="fade-up" data-aos-once="true" class="mt-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
      <wrapper-content
          @isOpen="openRecruitment = $event"
          v-for="(item, index) in listContent"
          :index="index + 1"
          :title="item.title"
          :content="item.content"
          :textLink="item.textLink"
          :link="item.link"
      />
    </div>
    <popup v-if="openRecruitment" @closeOTP="openRecruitment = false"></popup>
  </section>
</template>
<script lang="ts" setup>
import WrapperContent from './__WrapperContent.vue'
import Popup from './__PopupRecruitment.vue'

const {t} = useI18n()

const openRecruitment = ref(false)

const listContent = [
  {
    icon: '/images/about/protect.png',
    title: 'Khám phá vũ trụ bảo hiểm',
    content:
        'Chúng tôi kết nối nhu cầu của khách hàng với nghiệp vụ bảo hiểm của đối tác để tạo ra sản những phẩm bảo hiểm tối ưu',
    textLink: t('PRODUCT_VIEW'),
    link: t('PRODUCT_URL'),
    pattern: ''
  },
  {
    icon: '/images/about/bag.png',
    title: 'Tham gia đội ngũ IZIon24',
    content:
        'Chúng tôi kết nối nhu cầu của khách hàng với nghiệp vụ bảo hiểm của đối tác để tạo ra sản những phẩm bảo hiểm tối ưu',
    textLink: t('RECRUITMENT'),
    link: t('CONTACT_URL'),
    pattern: ''
  },
  {
    icon: '/images/about/message-heart.png',
    title: 'Liên hệ chúng tôi',
    content:
        'Chúng tôi kết nối nhu cầu của khách hàng với nghiệp vụ bảo hiểm của đối tác để tạo ra sản những phẩm bảo hiểm tối ưu',
    textLink: t('CONTACT'),
    link: t('CONTACT_URL'),
    pattern: ''
  }
]
</script>
<style lang=""></style>
