<template>
  <v-popup overlay closeOverlay onOpenLoadPage keyPopup="popupResultOtpSignup" :closeable="false">
    <template #header class="mb-7.5 relative">
            <span
                class="i-custom-close2 w-3 h-3 text-bw-24 absolute z-5 top-4.5 right-4.5 cursor-pointer"
                @click="$emit('update:visible', false)"
            ></span>
      <h5 class="text-32px font-[550] leading-10 text-black mb-3 gap-1 flex justify-center">
        <span>{{ $t('REQUEST') }}</span>
        <span class="text-primary">{{ $t('RECEIVED') }}</span>
      </h5>
      <p class="text-black/60 text-15px leading-6 text-center">
        {{ $t('IZION24_CONSULTING_TEAM_WILL_CALL_YOU_BACK') }}
      </p>
    </template>
    <div class="flex justify-center pt-3 pb-7.5">
      <div class="w-50 aspect-40/30">
        <img class="w-full h-full object-contain animationSway" src="/images/brand-iz4-3.png" alt="masgot"/>
      </div>
    </div>
    <template #footer>
      <button-primary
          :title="$t('CLOSE')"
          @click="$emit('update:visible', false)"
          class="w-full"
      ></button-primary>
    </template>
  </v-popup>
</template>

<script setup lang="ts"></script>

<style>
.box-content-popup-popupResultOtpSignup {
  @apply lg:!w-400px;
}
</style>
