<template>
  <v-popup v-model:visible="isOpen" overlay closeOverlay onOpenLoadPage keyPopup="popupRecruitment">
    <template #header class="relative">
            <span
                class="i-custom-close2 w-3 h-3 text-bw-24 absolute z-5 top-4.5 right-4.5 cursor-pointer"
                @click="closeOTP()"
            ></span>
      <div class="w-full">
        <h5
            class="text-2xl leading-3xl lg:leading-44px lg:text-30px font-[550] leading-10 text-center text-center gap-1"
        >
          {{ $t('RECRUITMENT1') }}
        </h5>
      </div>
    </template>
    <form @submit.prevent="onSubmit" id="form-request" class="form-submit mt-4 w-full">
      <div class="flex flex-col gap-y-3 lg:gap-y-6 max-h-80 lg:max-h-120 pr-3">
        <div class="w-full">
          <label
              for="name_about_page"
              :class="errorName ? '!text-error-01' : ''"
              class="uppercase text-bw-26 text-10px md:text-13px font-semibold block pb-2 uppercase"
          >{{ $t('FIELD_NAME') }}*</label
          >
          <input
              id="name_about_page"
              name="name"
              type="text"
              :class="errorName ? '!placeholder:text-error-01/80 border-error-01 !text-error-01' : ''"
              class="bg-white w-full rounded-28px py-3 px-6 lg:py-15px lg:px-5.5 focus:ring-0 b-1 b-solid b-[#CBCBCB] capitalize h-13.5 flex items-center"
              :placeholder="$t('INPUT_NAME')"
              @blur="checkName"
              v-model="infoForm.name"
          />
          <Transition name="fade">
                        <span class="text-error-01 pl-5 mt-2" v-if="errorName">
                            {{ $t('PLEASE_ENTER_YOUR_NAME') }}
                        </span>
          </Transition>
        </div>
        <div class="w-full">
          <label
              for="phone_about_page"
              :class="errorPhone ? '!text-error-01' : ''"
              class="uppercase text-bw-26 text-10px md:text-13px font-semibold block pb-2 uppercase"
          >{{ $t('FIELD_PHONE') }}*</label
          >
          <input
              id="phone_about_page"
              name="phone"
              type="text"
              :class="errorPhone ? '!placeholder:text-error-01/80 border-error-01 !text-error-01' : ''"
              class="bg-white w-full rounded-28px py-3 px-6 lg:py-15px lg:px-5.5 b-1 b-solid b-[#CBCBCB] h-13.5 flex items-center"
              :placeholder="$t('INPUT_PHONE')"
              @input="convertedPhoneNumber"
              @blur="checkPhone"
              v-model="infoForm.phone"
          />
          <Transition name="fade">
                        <span class="text-error-01 pl-5 mt-2" v-if="errorPhone">
                            {{ $t('INCORRECT_PHONE_NUMBER') }}
                        </span>
          </Transition>
        </div>

        <div class="w-full">
          <label
              for="email_about_page"
              :class="errorEmail ? '!text-error-01' : ''"
              class="text-bw-26 text-13px font-semibold block pb-2 uppercase"
          >
            Email*
          </label>
          <input
              id="email_about_page"
              type="email"
              class="border border-#CBCBCB font-medium outline-none w-full rounded-28px py-15px text-15px px-5.5 text-black/80 placeholder-bw-12/50 h-13.5 flex items-center"
              :class="errorEmail ? '!placeholder:text-error-01/80 border-error-01 !text-error-01' : ''"
              :placeholder="t('ENTER_YOUR_EMAIL')"
              v-model="infoForm.email"
              @blur="checkEmail()"
          />
          <Transition name="fade">
                        <span class="text-error-01 pl-5 mt-2" v-if="errorEmail">
                            {{ $t('PLEASE_ENTER_A_VALID_EMAIL') }}
                        </span>
          </Transition>
        </div>
        <div class="w-full">
          <label
              for="nomine_about_page"
              :class="errorPosition ? '!text-error-01' : ''"
              class="uppercase text-bw-26 text-10px md:text-13px font-semibold block pb-2 uppercase"
          >{{ $t('NOMINEE') }}</label
          >
          <input
              id="nomine_about_page"
              type="text"
              name="nomine"
              :class="errorPosition ? '!placeholder:text-error-01/80 border-error-01 !text-error-01' : ''"
              class="bg-white w-full rounded-28px py-3 px-6 lg:py-15px lg:px-5.5 b-1 b-solid b-[#CBCBCB] h-13.5 flex items-center"
              :placeholder="$t('NOMINEE2')"
              v-model="infoForm.position"
              @blur="checkNominee"
          />
          <Transition name="fade">
                        <span class="text-error-01 pl-5 mt-2" v-if="errorPosition">
                            {{ $t('PLEASE_ENTER_THE_POSITION_YOU_ARE_APPLYING_FOR') }}
                        </span>
          </Transition>
        </div>
        <div class="w-full">
          <label
              for="start_date_about_page"
              :class="errorStartDate ? '!text-error-01' : ''"
              class="uppercase text-bw-26 text-10px md:text-13px font-semibold block pb-2 uppercase"
          >
            {{ $t('THE_DAY_YOU_CAN_GO_TO_WORK') }}
          </label>
          <GlobalDateTimePicker
              :enable-time-picker="false"
              :min-date="new Date()"
              v-model="infoForm.start_date"
              @blur="checkWorkingDay"
              :class="errorStartDate ? '!placeholder:text-error-01/80 border-error-01 !text-error-01' : ''"
              class="bg-white w-full rounded-28px py-3 px-6 lg:py-15px lg:px-5.5 text-[#353535]/50 font-medium text-base b-1 b-solid b-[#CBCBCB] h-13.5"
          ></GlobalDateTimePicker>
          <Transition name="fade">
                        <span class="text-error-01 pl-5 mt-2" v-if="errorStartDate">
                            {{ notificationWorkingDay }}
                        </span>
          </Transition>
        </div>
        <div class="w-full">
          <label
              for="cv_about_page"
              :class="errorCV || errorLinkCV ? '!text-error-01' : ''"
              class="uppercase text-bw-26 text-10px md:text-13px font-semibold block pb-2 uppercase"
          >
            CV*
          </label>
          <input
              id="cv_about_page"
              type="text"
              name="nomine"
              :class="
                            errorCV || errorLinkCV ? '!placeholder:text-error-01 border-error-01 !text-error-01' : ''
                        "
              class="bg-white w-full rounded-28px py-3 px-6 lg:py-15px lg:px-5.5 b-1 b-solid b-[#CBCBCB] h-13.5 flex items-center"
              :placeholder="$t('YOUR_CV')"
              v-model="infoForm.cv"
              @blur="checkCV"
          />

          <Transition name="fade">
                        <span class="text-error-01 pl-5 mt-2" v-if="errorLinkCV">
                            {{ $t('PLEASE_ENTER_THE_CORRECT_CV_LINK') }}
                        </span>
          </Transition>

          <!-- <input
              id="input_file_cv"
              type="file"
              class="hidden"
              @change="onChangeFile"
              accept=".jpg, .jpeg, .png, .pdf"
          />
          <label
              for="input_file_cv"
              class="border border-#CBCBCB outline-none w-full rounded-30px py-5 px-5.5 text-black/80 flex flex-col gap-4 items-center my-3 flex justify-center cursor-pointer group"
              :class="errorCV ? '!placeholder:text-error-01/80 border-error-01 !text-error-01' : ''"
          >
              <div
                  :class="errorCV ? 'bg-error-01' : ' bg-primary'"
                  class="w-10 h-10 rounded-full flex justify-center items-center"
              >
                  <span v-if="nameFile" class="i-ic:baseline-file-download-done bg-white w-5 h-5"></span>
                  <span v-else class="i-ic:baseline-upload-file bg-white w-5 h-5"></span>
              </div>
              <p v-if="nameFile" class="break-all text-center">{{ nameFile }}</p>
              <p v-else class="flex items-center gap-1">
                  <span :class="errorCV ? 'textt-error-01' : ' text-primary'" class="font-semibold">{{
                      $t('CLICK_TO_UPLOAD')
                  }}</span>
                  <span> {{ $t('YOUR_CV1') }}</span>
              </p>
          </label>
          <Transition name="fade">
              <span class="text-error-01 pl-5 mt-2" v-if="errorCV">
                  {{ $t('PLEASE_ENTER_YOUR_CV_LINK') }}
              </span>
          </Transition> -->
        </div>
      </div>
    </form>

    <template #footer>
      <div class="w-full flex flex-col justify-center mt-5">
        <GlobalVerifyForm
            class="w-full flex justify-center mb-3 lg:mb-5"
            :error="errorChecked"
            v-model:checked="isChecked"
        />
        <button
            class="relative btn-submit-contact flex-1 bg-primary text-white font-semibold uppercase text-sm block px-11.5 rounded-30px mt-auto flex items-center justify-center py-17px min-h-13.5 h-13.5"
            :class="successSubmit ? '' : 'pointer-events-none'"
            @click="onSubmit"
        >
          <p v-if="successSubmit">{{ $t('RECRUITMENT') }}</p>
          <div v-else class="absolute loaderForm"></div>
        </button>
      </div>
    </template>
  </v-popup>

  <popup-result-success v-model:visible="isSuccess"></popup-result-success>
  <popup-result-fail v-model:visible="isFail" :title="notificationError" :intro="introError"></popup-result-fail>
</template>

<script setup lang="ts">
import {useReCaptcha} from 'vue-recaptcha-v3'
import PopupResultSuccess from './__PopupResultSuccess.vue'
import PopupResultFail from './__PopupResultFail.vue'
// const directus = useDirectus()
const {t} = useI18n()

const emits = defineEmits(['closeOTP'])

const closeOTP = () => {
  isOpen.value = false
}

const infoForm = ref({
  lang: useRoute().params.langCode,
  name: '',
  phone: '',
  email: '',
  position: '',
  start_date: '',
  cv: ''
  // file_cv: {}
})

const successSubmit = ref(true)
const isOpen = ref(true)
const notificationError = ref()
const introError = ref()
const isSuccess = ref(false)
const isFail = ref(false)
const loadingUpFile = ref(false)
const nameFile = ref()
const errorPhone = ref(false)
const errorName = ref(false)
const errorEmail = ref(false)
const errorChecked = ref(false)
const errorPosition = ref(false)
const errorStartDate = ref(false)
const notificationWorkingDay = ref()
// const errorCV = ref(false)
const errorLinkCV = ref(false)

const isChecked = ref()

const checkName = () => {
  if (infoForm.value?.name) {
    errorName.value = false
  } else {
    errorName.value = true
  }
}

const convertedPhoneNumber = () => {
  if (infoForm.value.phone !== '' && infoForm.value.phone[0] === '0') {
    var convertedPhoneNumber = infoForm.value.phone?.substring(1)
    infoForm.value.phone = '+84' + convertedPhoneNumber
  }
}
const checkPhone = () => {
  var vnf_regex = /((09|03|07|08|05)+([0-9]{8})\b)/g
  if (infoForm.value.phone) {
    var convertedPhoneNumber = infoForm.value.phone.replace(/^\+84/, '0')
    if (vnf_regex.test(convertedPhoneNumber)) {
      errorPhone.value = false
    } else {
      errorPhone.value = true
    }
  } else {
    errorPhone.value = true
  }
}
const checkEmail = () => {
  let filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/
  if (infoForm.value.email) {
    if (filter.test(infoForm.value.email)) {
      errorEmail.value = false
    } else {
      errorEmail.value = true
    }
  } else {
    errorEmail.value = true
  }
}
const checkNominee = () => {
  if (infoForm.value.position) {
    errorPosition.value = false
  } else {
    errorPosition.value = true
  }
}
const checkWorkingDay = () => {
  if (infoForm.value.start_date) {
    var inputDate = new Date(infoForm.value.start_date)
    var currentDate = new Date()
    if (inputDate < currentDate) {
      errorStartDate.value = true
      notificationWorkingDay.value = t('DATE_OF_GOING_TO_WORK_MUST_BE_AFTER_THE_CURRENT_DATE')
    } else {
      errorStartDate.value = false
    }
  } else {
    errorStartDate.value = true
    notificationWorkingDay.value = t('PLEASE_SELECT_THE_DATE_YOU_CAN_GO_TO_WORK')
  }
}
const checkCV = () => {
  // const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i
  const urlRegex =
      /^(https?:\/\/)?(((?:[a-z\d]([a-z\d-]*[a-z\d])*)\.)+[a-z]{2,}|(\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}))(:\d+)?(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(#[-a-z\d_]*)?$/i

  if (infoForm.value.cv && urlRegex.test(infoForm.value.cv)) {
    errorLinkCV.value = false
  } else {
    errorLinkCV.value = true
  }
}

// const checkCV = () => {
//     const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i

//     if (infoForm.value.cv || infoForm.value.file_cv) {
//         errorCV.value = false
//         if (infoForm.value.cv && urlRegex.test(infoForm.value.cv)) {
//             errorLinkCV.value = false
//         } else {
//             errorLinkCV.value = true
//         }
//     } else {
//         errorCV.value = true
//     }
// }

const checkChecked = () => {
  if (isChecked.value) {
    errorChecked.value = false
  } else {
    errorChecked.value = true
  }
}

watch(
    () => isChecked.value,
    () => {
      checkChecked()
    }
)

watch(
    () => isOpen.value,
    () => {
      if (!isFail.value && !isSuccess.value) {
        emits('closeOTP')
      }
    }
)

watch(
    () => isFail.value,
    (newValue) => {
      if (!newValue) {
        emits('closeOTP')
      }
    }
)

watch(
    () => isSuccess.value,
    (newValue) => {
      if (!newValue) {
        emits('closeOTP')
      }
    }
)

// const onChangeFile = async (e) => {
//     const files = e.target.files
//     const formData = new FormData()
//     nameFile.value = files[0]?.name
//     formData.append('file', files[0])
//     infoForm.value.file_cv = formData
//     checkCV()
// }

// const uploadFile = async () => {
//     loadingUpFile.value = true

//     const res: any = await directus('/files', {
//         method: 'POST',
//         body: infoForm.value.file_cv
//     })
//     console.log(`output->res.data?.title`, res)

//     loadingUpFile.value = false
// }

const recaptchaInstance = useReCaptcha()
const recaptcha = async () => {
  await recaptchaInstance?.recaptchaLoaded()
  const token = await recaptchaInstance?.executeRecaptcha('login')

  return token
}
const onSubmit = async (): Promise<void> => {
  checkChecked()
  checkName()
  checkPhone()
  checkEmail()
  checkNominee()
  checkWorkingDay()
  checkCV()
  // uploadFile()

  if (
      !errorPhone.value &&
      !errorName.value &&
      !errorChecked.value &&
      !errorEmail.value &&
      !errorPosition.value &&
      !errorStartDate.value &&
      !errorLinkCV.value
  ) {
    const token = await recaptcha()
    successSubmit.value = false
    infoForm.value.name = capitalizeFirstLetterOfWords(infoForm.value.name)
    fetch(`/api/application?response=${token}`, {
      method: 'POST',
      body: JSON.stringify(infoForm.value)
    })
        .then((response) => response.json())
        .then((data) => {
          successSubmit.value = true
          if (data?.code == 201) {
            isOpen.value = false
            isSuccess.value = true
            infoForm.value = {
              lang: useRoute().params.langCode,
              name: '',
              phone: '',
              email: '',
              position: '',
              start_date: '',
              cv: ''
              // file_cv: {}
            }
          } else {
            isOpen.value = false
            isFail.value = true
            notificationError.value = t('THE_SERVER_IS_HAVING_PROBLEMS')
            introError.value = t('SORRY_WE_ARE_EXPERIENCING_SOME_TECHNICAL2')
          }
        })
  }
}
</script>

<style scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  display: none;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

.dp__main {
  @apply w-full block;
}
</style>
