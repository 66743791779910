<template>
  <section class="">
    <div class="relative w-full h-full relative pt-10 lg:pt-27 pb-10 lg:pb-30">
      <div class="absolute inset-0">
        <img src="/images/about/bg-value.png" alt="background" class="w-full h-full object-cover"/>
      </div>
      <div
          data-aos="fade-up"
          data-aos-duration="500"
          data-aos-easing="ease-in-out"
          data-aos-once="true"
          class="container relative flex flex-col justify-center md:items-center lg:mb-27"
      >
        <v-text
            class="text-xs xl:text-sm font-semibold text-white/70 uppercase"
            tag="p"
            option_key="about.subtileValue"
        ></v-text>
        <v-text
            class="text-25px xl:text-33px font-[550] text-white pt-3 w-auto md:w-150 xl:w-185 md:text-center !leading-11 pb-10 lg:pb-22 !leading-37px !lg:leading-46px"
            tag="p"
            option_key="about.tileValue"
        ></v-text>
        <div
            class="hidden lg:block -z-1 absolute top-5 lg:top-0 left-0 !opacity-30 !lg:opacity-100 lg:-left-10 xl:-left-1"
        >
          <div class="w-45 xl:w-58 aspect-58/87">
            <v-image
                option_key="about.imageValue1"
                :width="500"
                class="w-full h-full object-cover rounded-15px transform hover:scale-105 duration-400"
            >
            </v-image>
          </div>
        </div>
        <div
            class="hidden lg:block -z-1 absolute -bottom-40 xl:-bottom-50 lg:top-full right-0 lg:left-80 !opacity-30 !lg:opacity-100"
        >
          <div class="w-46 aspect-46/30">
            <v-image
                option_key="about.imageValue2"
                :width="500"
                class="w-full h-full object-cover rounded-15px transform hover:scale-105 duration-400"
            >
            </v-image>
          </div>
        </div>
        <div class="hidden lg:block -z-1 absolute -bottom-35 xl:-bottom-45 lg:-right-20 xl:-right-5">
          <div class="w-70 xl:w-81 aspect-81/54">
            <v-image
                :width="300"
                option_key="about.imageValue3"
                class="w-full h-full object-cover rounded-15px transform hover:scale-105 duration-400"
            >
            </v-image>
          </div>
        </div>
        <div class="hidden lg:block -z-1 absolute top-0 xl:-right-5 lg:-right-20 !opacity-30 !lg:opacity-100">
          <div class="w-51 aspect-51/34">
            <v-image
                :width="300"
                option_key="about.imageValue4"
                class="h-full w-full object-cover rounded-15px transform hover:scale-105 duration-400"
            >
            </v-image>
          </div>
        </div>
      </div>
      <div
          class="container relative z-1 flex flex-col md:flex-row items-start lg:items-center justify-center divide lg:divide-x divide-white/30 gap-4.5 lg:gap-6 mt-0 lg:mt-60 pb-10"
      >
        <div
            data-aos="fade-up"
            data-aos-once="true"
            class="flex items-center gap-3.5 lg:gap-2 text-white/90 w-full lg:w-auto"
        >
          <!-- <span class="rounded-full border border-white w-5 h-5 block flex-none lg:hidden"></span> -->
          <v-text
              class="text-44px font-semibold inline-block"
              tag="p"
              option_key="about.numberValue1"
              default_value=" 8y"
          ></v-text>
          <v-text
              class="text-15px font-semibold max-w-32 leading-5"
              tag="p"
              option_key="about.numberContentValue1"
              default_value=" Hơn 8 năm trong lĩnh vực"
          ></v-text>
        </div>
        <div class="w-full h-1px md:hidden bg-white/30"></div>
        <div
            data-aos="fade-up"
            data-aos-once="true"
            class="flex items-center gap-3.5 lg:gap-2 text-white/90 lg:pl-6 w-full lg:w-auto"
        >
          <!-- <span class="rounded-full border border-white w-5 h-5 block flex-none lg:hidden"></span> -->
          <v-text
              class="text-44px font-semibold inline-block"
              tag="p"
              option_key="about.numberValue2"
              default="99%"
          ></v-text>
          <v-text
              class="text-15px font-semibold max-w-32 leading-5"
              option_key="about.numberContentValue2"
              default_value="99% khách hàng hài lòng"
          ></v-text>
        </div>
        <div class="w-full h-1px md:hidden bg-white/30"></div>

        <div
            data-aos="fade-up"
            data-aos-once="true"
            class="flex items-center gap-3.5 lg:gap-2 text-white/90 lg:pl-6 w-full lg:w-auto"
        >
          <!-- <span class="rounded-full border border-white w-5 h-5 block flex-none lg:hidden"></span> -->
          <v-text
              class="text-44px font-semibold inline-block"
              option_key="about.numberValue3"
              default_value="50k"
          ></v-text>
          <v-text
              class="text-15px font-semibold max-w-32 leading-5"
              tag="p"
              option_key="about.numberContentValue3"
              default_value="50k khách hàng trong 8 năm"
          ></v-text>
        </div>
        <!-- <v-le-image
            class="w-full max-h-70 max-w-md mx-auto h-full aspect-105/71 image-cover image-rounded-5 transform hover:scale-105 duration-400"
            optKey="imageValue1"
            default="95a47412-b544-40d6-844a-6a496f5df597"
        ></v-le-image> -->
        <!-- Slide image mobile -->
        <Swiper
            :modules="[SwiperAutoplay]"
            :space-between="20"
            :breakpoints="{
                        320: { slidesPerView: 1.1 },
                        600: { slidesPerView: 1.5 }
                    }"
            :autoplay="{
                        delay: 10000,
                        disableOnInteraction: false
                    }"
            :grabCursor="true"
            :loop="true"
            class="!md:hidden"
        >
          <SwiperSlide class="!h-auto">
            <div class="w-full max-h-70 max-w-md mx-auto h-full aspect-105/71">
              <v-image
                  option_key="about.imageValue1"
                  class="w-full h-full object-cover rounded-15px"
              ></v-image>
            </div>
          </SwiperSlide>
          <SwiperSlide class="!h-auto">
            <div class="w-full h-full max-h-70 max-w-md mx-auto aspect-105/71">
              <v-image
                  option_key="about.imageValue2"
                  class="w-full h-full object-cover rounded-15px"
              ></v-image>
            </div>
          </SwiperSlide>
          <SwiperSlide class="!h-auto">
            <div
                class="w-full max-h-70 max-w-md mx-auto h-full aspect-105/71 image-cover image-rounded-5 transform duration-400"
            >
              <v-image
                  option_key="about.imageValue3"
                  class="w-full h-full object-cover rounded-15px"
              ></v-image>
            </div>
          </SwiperSlide>
          <SwiperSlide class="!h-auto">
            <div
                class="w-full max-h-70 max-w-md mx-auto h-full aspect-105/71 image-cover image-rounded-5 transform duration-400"
            >
              <v-image
                  option_key="about.imageValue4"
                  class="w-full h-full object-cover rounded-15px"
              ></v-image>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  </section>
</template>
<script lang="ts" setup></script>
